import VersionLayout from '@routes/versions/ui/layout/VersionsLayout';
import { NotFoundPage } from '@routes/not-found';
import { t } from '@lib/i18n';
import { graphql } from 'gatsby';

// ----------------------------------------------------------------------------

const Template = ({ data, pageContext }) => {
  const { allMarkdownRemark } = data;
  if (allMarkdownRemark === null || allMarkdownRemark === undefined) {
    return NotFoundPage;
  }
  const postId = pageContext.id;
  const currentPage = allMarkdownRemark.edges.filter((n) => n.node.id === postId)[0];
  if (currentPage === null || currentPage === undefined) {
    return NotFoundPage;
  }
  const nextVersion = currentPage.previous;
  const previousVersion = currentPage.next;
  const { frontmatter, htmlAst } = currentPage.node;
  frontmatter.seo.image = t('seo.versions.image');
  return (
    <VersionLayout
      htmlAst={htmlAst}
      frontmatter={frontmatter}
      currentVersion={frontmatter}
      nextVersion={nextVersion}
      previousVersion={previousVersion}
      banner
    />
  );
};

export default Template;

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {frontmatter: {type: {eq: "release"}}}
      sort: {fields: frontmatter___createdAt, order: DESC}
    ) {
      edges {
        next {
          frontmatter {
            slug
            seo {
              link
            }
          }
        }
        node {
          id
          htmlAst
          frontmatter {
            slug
            createdAt
            version
            type
            language
            seo{
              title
              description
              link
            }
          }
        }
        previous {
          frontmatter {
            slug
            seo {
              link
            }
          }
        }
      }
    }
  }
`;
